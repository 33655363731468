import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { Card } from "ustrive-ui";
import CardHeader from "./CardHeader";
import StyledCard from "./StyledCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

const StyledList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

const StyledItem = styled.li`
  padding: 15px 0px 5px 0px;
  border-bottom: 1px solid #e1e6f2;
  font-size: 14px;
  :last-of-type {
    border-bottom: 0px;
  }
  a {
    color: #56648a;
    text-decoration: none;
  }
  svg {
    margin-right: 10px;
  }
`;

const items = [
  {
    title: "Logo Image",
    complete: data => (data.logo ? true : false),
  },{
    title: "Admissions Email",
    complete: data => (data.email ? true : false),
  },
  {
    title: "College Overview",
    complete: data => (data.overview ? true : false),
  },
  {
    title: "Helpful Links",
    complete: data => !isEmpty(data.links),
  },
  {
    title: "Banner Images",
    complete: data => !isEmpty(data.images),
  },
  {
    title: "Featured Videos",
    complete: data => !isEmpty(data.videos),
  },
  {
    title: "Your Programs",
    complete: data => !isEmpty(data.programs),
  }
];

const ProfileChecklist = ({ college }) => (
  <StyledCard padding="0px">
    <CardHeader>Complete Your Profile</CardHeader>
    <Card padding="20px">
      <StyledList>
        {items.map((item, index) => (
          <StyledItem key={index}>
            <Link to="/edit">
              <FontAwesomeIcon
                icon={
                  item.complete(college) ? faCheckCircle : faCircle
                }
                color="#29345C"
                size="lg"
              />
              {item.title}
            </Link>
          </StyledItem>
        ))}
      </StyledList>
    </Card>
  </StyledCard>
);

export default ProfileChecklist;
