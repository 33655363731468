import { run } from "./parse";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const toggleType = (type) => {
  if (!type) return "";
  return type === "student" ? "mentor" : "student";
};

const formatPhoneNumber = (s) => {
  var s2 = ("" + s).replace("+1", "").replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
};

export const removeCountryCode = (phone = "") => {
  return phone.substring(phone.length - 10);
};

export function downloadCsv(csvString) {
  var blob = new Blob([csvString]);
  const filename = `ustrive_${new Date().getTime()}.csv`;
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var a = window.document.createElement("a");

    a.href = window.URL.createObjectURL(blob, {
      type: "text/plain",
    });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

async function onExport(endpoint, params, count) {
  try {
    const response = await run("createReport", {
      ...params,
      endpoint,
      count,
    });
    downloadCsv(response);
  } catch (err) {
    console.error(err);
  }
}

async function getCount(endpoint, params) {
  try {
    const count = await run(endpoint, {
      ...params,
      count: true,
    });

    return count;
  } catch (err) {
    console.log(err);
  }
}

const educationMajors = (arr = []) =>
  arr.map((record) => record.majors.map((major) => major.label)).flat();

export {
  capitalize,
  toggleType,
  formatPhoneNumber,
  onExport,
  getCount,
  educationMajors,
};
