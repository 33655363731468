import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { TableTools, UserTable } from "ustrive-ui";
import { run } from "../../helpers/parse";
import { onExport, getCount } from "../../helpers/utils";
import { UserContext } from "../../providers/User";
import { PageDescription } from "../Elements/PageDescription";
import { StyledPage } from "../Elements/StyledPage";

const ENDPOINT = "accountsList";

const Students = () => {
  const { user } = useContext(UserContext);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [params, updateParams] = useState({
    type: "student",
  });

  async function filter(paginate = 0, values = []) {
    setLoading(true);

    const newPage = page + paginate;

    const filterParams = Object.assign(
      {},
      ...values.map((param) => ({ [param["name"]]: param.value }))
    );

    const newParams = {
      ...filterParams,
      type: "student",
      page: newPage,
    };

    try {
      const countResponse = await getCount(ENDPOINT, newParams);
      const response = await run(ENDPOINT, newParams);
      setResults(response);
      setCount(countResponse);
      setPage(newPage);
      updateParams(newParams);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    (async function init() {
      const response = await getCount(ENDPOINT, params);
      setCount(response);
    })();
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage id="page">
      {user.branch.college && (
        <PageDescription>
          These {user.branch.name} students have signed up on UStrive.
        </PageDescription>
      )}
      <TableTools
        filter={filter}
        fields={["search", "accountStatus", "graduation"]}
        page={page}
        enableExport={true}
        onExport={() => onExport(ENDPOINT, params, count)}
        count={count}
        queryType="accounts"
      />
      <div className="row">
        <div className="col-md-12">
          <UserTable
            emptyText={`No students found.`}
            loading={loading}
            results={results}
            columns={["Student", "Email", "Status", "Location", "Registered"]}
            type="student"
          />
        </div>
      </div>
    </StyledPage>
  );
};

export default Students;
