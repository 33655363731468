import styled from "styled-components";
export const TitleText = styled.h2`
  color: #29345c;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
`;

export const StyledList = styled.ul`
  padding: 0px;
  margin-top: 28px;
  margin-bottom: 0px;
  list-style: none;
  li {
    padding: 14px 0px;
    border-bottom: 1px solid #d0d8eb;

    font-size: 16px;
    line-height: 22px;
    color: #29345c;
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 600;
      white-space: nowrap;
      margin-right: 30px;
    }
    :last-of-type {
      border-bottom: 0px;
    }
  }
`;
