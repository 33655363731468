import React, { useState, useEffect } from "react";
import { UserTable } from "ustrive-ui";
import { run } from "../../helpers/parse";

const UnmatchedStudents = () => {
  const [results, updateResults] = useState([]);
  const [loading, updateLoading] = useState(true);

  useEffect(() => {
    async function getResults() {
      try {
        const response = await run("accountsList", {
          type: "student",
          status: "registered"
        });

        updateResults(response);
        updateLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    getResults();
  }, []);

  return (
    <UserTable
      title="Unmatched Students"
      emptyText="All students are currently matched."
      emptyIcon="check"
      loading={loading}
      results={results}
      columns={["Student", "Email"]}
      type="student"
    />
  );
};

export default UnmatchedStudents;
