import React, { useState, useEffect } from "react";
import StyledCard from "../../Elements/StyledCard";
import CardHeader from "../../Elements/CardHeader";
import { run } from "../../../helpers/parse";
import { EventTable } from "ustrive-ui";

const LatestActivity = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  async function getEvents() {
    try {
      const response = await run("eventsList", {
        includeNames: ["createTracker", "updateTracker", "contactCollege"],
        userType: "student",
        collegeId: true
      });
      setEvents(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <StyledCard padding="0px">
      <CardHeader>Latest Activity</CardHeader>
      <EventTable
        emptyText="No activity found."
        loading={loading}
        results={events}
        columns={["User", "Event", "Timestamp"]}
      />
    </StyledCard>
  );
};

export default LatestActivity;
