import React, { useState } from "react";
import { ToastAlert } from "ustrive-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";

import { run } from "../../../helpers/parse";
import { StyledButton, StyledTextareaField } from "./styled";
import c from "./content.json";

const CollegeOverview = ({ college, onSuccess }) => {
  const [saving, setSaving] = useState(false);
  const [overview, setOverview] = useState(college.overview);

  async function save() {
    try {
      setSaving(true);

      await run("updateCollege", {
        overview,
      });

      onSuccess({
        ...college,
        overview,
      });

      toast.success(
        <ToastAlert
          title="Overview Updated"
          description={c.successDescription}
        />
      );
      setSaving("");
    } catch (err) {
      setSaving("");
      toast.error(<ToastAlert />);
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-end mb-3">
        <h3 className="font-weight-bold h6 m-0">{c.title}</h3>
        <div data-cy="submit-overview">
          <StyledButton size="small" onClick={save} width="auto" hasIcon>
            <FontAwesomeIcon
              icon={saving ? faSpinner : faSave}
              color="#29345C"
              size="lg"
            />
          </StyledButton>
        </div>
      </div>
      <StyledTextareaField
        placeholder={c.placeholder}
        value={overview}
        rows="5"
        onChange={setOverview}
      ></StyledTextareaField>
    </div>
  );
};

export default CollegeOverview;
