import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationArrow,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar, Divider, SuggestionTags } from "ustrive-ui";

import { educationMajors, formatPhoneNumber } from "../../../helpers/utils";
import { MentorName, MentorCollege, ContactInfo, InfoRow } from "./styled";

const AccountCard = function ({ profile, contactInfo, viewAccount }) {
  return profile ? (
    <div className="row">
      <div className="col-md-12 text-center">
        <Avatar size={80} url={profile.avatar} />
        <MentorName>{`${profile.firstName} ${profile.lastName}`}</MentorName>
        {profile.type === "mentor" && profile.education[0] && (
          <>
            <MentorCollege>{profile.education[0].college.label}</MentorCollege>
            <Divider margin={20} />
            <div className="row">
              <div className="col-md-12 text-left">
                <SuggestionTags
                  employer={profile.employer}
                  occupation={profile.occupation}
                />
                {educationMajors(profile.education).length > 0 && (
                  <SuggestionTags majors={educationMajors(profile.education)} />
                )}
              </div>
            </div>
          </>
        )}
        {profile.type === "student" && (
          <>
            <MentorCollege>{profile.grade}</MentorCollege>
            <Divider margin={20} />
            <ContactInfo>
              <InfoRow>
                <FontAwesomeIcon icon={faLocationArrow} />
                {profile.city},{profile.state}
              </InfoRow>
            </ContactInfo>
          </>
        )}

        <Divider margin={20} />
        {contactInfo && (
          <ContactInfo>
            <InfoRow>
              <FontAwesomeIcon icon={faEnvelope} />
              {profile.email}
            </InfoRow>
            <InfoRow>
              <FontAwesomeIcon icon={faMobileAlt} />
              {profile.phone ? formatPhoneNumber(profile.phone) : "No Phone"}
            </InfoRow>
          </ContactInfo>
        )}
        {viewAccount && (
          <Link
            to={`/account/${profile.id}`}
            className="btn btn-block btn-primary">
            View Account
          </Link>
        )}
      </div>
    </div>
  ) : (
    <pre>Missing prop: profile</pre>
  );
};

AccountCard.defaultProps = {
  contactInfo: false,
  viewAccount: false,
};

export default AccountCard;
