import React, { useState, useEffect } from "react";
import { LoadingIcon, AdminMatchPage } from "ustrive-ui";
import { run } from "../../helpers/parse";

const Match = ({ match, history }) => {
  const [object, setObject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const { objectId } = match.params;

  const getAccount = async () => {
    setLoading(true);
    try {
      const response = await run("matchesList", {
        objectId,
      });
      setObject(response[0]);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const getMessages = async () => {
    setLoading(true);
    try {
      const response = await run("messagesList", {
        matchId: objectId,
      });
      setMessages(response);
      setLoading(false);
    } catch (err) {
      console.error("messagesList", err);
      setLoading(false);
    }
  };

  const deleteMatch = async (reason) => {
    if (!reason) return alert("Please select a reason.");
    if (
      window.confirm(
        "Are you sure you want to delete this match? This can't be undone."
      )
    ) {
      try {
        await run("deleteMatch", {
          matchId: objectId,
          reason,
        });
        history.push(`/matches`);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getAccount();
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  return !loading && object ? (
    <div id="page">
      <AdminMatchPage
        object={object}
        messages={messages}
        deleteMatch={deleteMatch}
      />
    </div>
  ) : (
    <LoadingIcon />
  );
};

export default Match;
