import React, { useState, useEffect } from 'react';
import StyledCard from "./StyledCard";
import CardHeader from "./CardHeader";
import { Card } from "ustrive-ui";
import dayjs from "dayjs";
import { Pie } from "react-chartjs-2";
import { run } from "../../helpers/parse";

const graphLabels = [
    "Interested",
    "Applying",
    "Submitted",
    "Rejected",
    "Waitlisted",
    "Accepted",
    "Enrolled"
];

const ApplicationStatus = () => {

    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        async function getTrackers() {
            try {
                const response = await run("listTrackers", {
                    afterDate: dayjs()
                        .subtract(12, "months")
                        .toDate()
                });

                const dataFill = [];

                graphLabels.forEach((label, index) => {
                    dataFill[index] = response.filter(
                        tracker => tracker.status === label.toLowerCase()
                    ).length;
                });

                setPieData(dataFill);
            } catch (err) {
                console.error(err);
            }
        }
        getTrackers();
    }, []);

    return (
        <StyledCard padding="0px">
            <CardHeader>Application Status</CardHeader>
            <Card padding="20px">
                <Pie
                    data={{
                        labels: graphLabels,
                        datasets: [
                            {
                                data: pieData,
                                backgroundColor: [
                                    "#9FABC3",
                                    "#ADB8CC",
                                    "#C0C9DA",
                                    "#D5DDEC",
                                    "#E4E9F4",
                                    "#DCEDFF"
                                ]
                            }
                        ]
                    }}
                    height={360}
                    legend={{
                        fullWith: true,
                        reverse: true,
                        position: 'bottom',
                        align: 'start',
                        fullWidth: false,
                        labels: {
                            fontColor: "#56648A",
                            fontFamily: "Sofia Pro"
                        }
                    }}
                    options={{
                        maintainAspectRatio: false
                    }}
                />
            </Card>
        </StyledCard>
    )

}

export default ApplicationStatus;