import React, { useState } from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import { isEmpty, remove } from "lodash";
import { Card, Button, LoadingIcon } from "ustrive-ui";
import Reorder, { reorder } from "react-reorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

const opts = {
  playerVars: {
    autoplay: 1,
  },
};

const VideoTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem;
  background-color: #f9f9fc;
`;

const FeaturedVideos = ({ videos, updateCollege }) => {
  const [saving, setSaving] = useState(false);

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.stopVideo();
  };

  const deleteVideo = async (index) => {
    if (!window.confirm("Are you sure you want to delete this video?")) return;
    try {
      setSaving(true);
      const newVideos = remove(videos, (n, i) => i !== index);
      await updateCollege(newVideos);
      setSaving(false);
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
  };

  const onReorder = async (event, previousIndex, nextIndex, fromId, toId) => {
    try {
      setSaving(true);
      const newVideos = reorder(videos, previousIndex, nextIndex);
      await updateCollege(newVideos);
      setSaving(false);
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
  };

  return (
    <div>
      {saving ? (
        <div className="col-md-12 mb-4">
          <LoadingIcon />
        </div>
      ) : isEmpty(videos) ? (
        <h3 className="text-center mt-4 mb-4">
          There are currently no videos.
        </h3>
      ) : (
        <Reorder
          reorderId="profile-videos"
          autoScroll={false}
          onReorder={onReorder}
          holdTime={150}
          style={{
            listStyle: "none",
            padding: "0px",
            margin: "0px",
          }}
          className="row"
          component="ul">
          {videos.map((video, index) => (
            <li className="col-md-6" key={index}>
              <Card padding="0px">
                <YouTube
                  className="video-embed"
                  containerClassName="video-embed-container"
                  videoId={video.url.split("=")[1]}
                  opts={opts}
                  onReady={onReady}
                />
                <VideoTitleDiv>
                  <div>
                    <h5 className="mt-0 mb-0 text-dark font-weight-bold">
                      {video.title}
                    </h5>
                    <div data-cy="deleteVideo">
                      <Button
                        size="small"
                        onClick={() => deleteVideo(index)}
                        className="text-danger bg-transparent p-0 h-initial border-0 font-weight-light"
                        width="auto"
                        hasIcon>
                        <FontAwesomeIcon
                          size="sm"
                          className="text-danger"
                          icon={faTrashAlt}
                        />
                        Delete
                      </Button>
                    </div>
                  </div>
                </VideoTitleDiv>
              </Card>
            </li>
          ))}
        </Reorder>
      )}
    </div>
  );
};

FeaturedVideos.defaultProps = {
  videos: [],
};

export default FeaturedVideos;
