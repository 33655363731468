import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { TableTools, MessageTable } from "ustrive-ui";
import { run } from "../../helpers/parse";
import { downloadCsv } from "../../helpers/utils";
import { UserContext } from "../../providers/User";
import { PageDescription } from "../Elements/PageDescription";
import { StyledPage } from "../Elements/StyledPage";
import { Alert } from 'ustrive-ui';

const ENDPOINT = "messagesList";

const Messages = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [descending, setDescending] = useState(true);
  const [exported, setExported] = useState(false);

  async function filter(paginate = 0) {
    setLoading(true);
    const newPage = page + paginate;

    try {
      const response = await run(ENDPOINT, {
        page: newPage,
        orderBy,
        descending,
      });

      setMessages(response);
      setPage(newPage);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  async function onExport() {
    try {
      const endpoint = user.branch.college ? ENDPOINT : 'messagesListCorporate';
      const response = await run("createReport", {
        endpoint,
        count,
      });
      if (user.branch.college) {
        downloadCsv(response)
      } else {
        setExported(true);
        return response;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getCount() {
    try {
      const response = await run(ENDPOINT, {
        count: true,
      });
      setCount(response);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateOrder(_orderBy, _descending) {
    setOrderBy(_orderBy);
    setDescending(_descending);
  }

  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, descending]);

  return (
    <StyledPage id="page">
      {user.branch.college && (
        <PageDescription>
          Analyze the messages that {user.branch.name}’s students are exchanging
          with their mentors.
        </PageDescription>
      )}
      {
        exported &&  (
          <div className="row">
            <div className="col-md-12 mb-4">
              <Alert
                className="primary"
                title="Messages exported"
                icon="envelope"
                description="A CSV file with your export has been sent to your email address"
              />
            </div>
          </div>
        )
      }
      <TableTools
        filter={filter}
        page={page}
        fields={[]}
        enableExport={true}
        onExport={onExport}
        count={count}
        queryType="messages"
      />
      <div className="row">
        <div className="col-md-12">
          <MessageTable
            emptyText="No messages found."
            loading={loading}
            results={messages}
            orderBy={orderBy}
            descending={descending}
            updateOrder={updateOrder}
          />
        </div>
      </div>
    </StyledPage>
  );
};

export default Messages;
