import styled from "styled-components";

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const BannerImage = styled.img`
  cursor: move;
  border-radius: 0.5rem;
`;

export const ImageWrapper = styled.div`
  :hover {
    .delete-button {
      opacity: 1 !important;
    }
  }
`;

export const DeleteImage = styled.button`
  display: flex;
  opacity: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 1.25rem;
  left: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 9999px;
  background: #ffeaea;
  border: 2px solid white;
  box-shadow: 0px 1px 9px rgba(225, 53, 53, 0.1);
`;
