import React, { useState, useEffect } from 'react';
import { Card, InputField, Button } from 'ustrive-ui';
import { run } from '../../helpers/parse';

const CreateMatch = ({ student, onSuccess }) => {

    const [email, setEmail] = useState('');
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState('');

    async function findMentor(name) {

        try {

            const mentors = await run('accountsList', {
                search: name || email,
                type: 'mentor',
                status: "available"
            });

            if (mentors.length === 0) throw Error("No available mentor found with that information.");
            if (mentors.length > 1) throw Error("More than one available mentor found with that information.");

            const mentor = mentors[0];

            setEmail(mentor.email);

            return mentor;

        } catch(err) {
            setError(err.message);
            setCreating(false);
        }

    }

    async function createMatch() {

        setCreating('Finding mentor...');
        setError('');

        try {

            if (email.length === 0) throw Error("Please provide an email address.");

            const mentor = await findMentor();

            if (!mentor) throw Error("No available mentor found with that email address.");

            setCreating('Creating match...');

            await run('createMatch', {
                mentorId: mentor.id,
                studentId: student.id
            });
        
            onSuccess();

        } catch(err) {
            setError(err.message);
            setCreating(false);
        }

    }

    useEffect(() => {
        try {
            if (student.manualMatch) findMentor(student.manualMatch);
        } catch(err) {
            setError(err.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [student.manualMatch]);

    return student.matches.length === 0 ? (
        <Card padding="20px 40px 20px 40px">
            <h5 className="mt-0">Pair with Mentor</h5>
            <div className="row">
                <div className="col-md-12 text-center">
                    <p>This student hasn't been matched with a mentor yet.</p>
                    <p>Enter a mentor email to pair the student with a mentor.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 offset-md-3 text-center">
                    <InputField value={email} onChange={setEmail} placeholder="mentor@email.com" className="mb-2" />
                    { student.manualMatch && (<small className="mb-2 display-block">This student set <strong>{student.manualMatch}</strong> as their mentor.</small>) }
                    <Button className="primary" onClick={createMatch} disabled={creating}>Create Match</Button>
                    
                    <p className="text-danger">{error}</p>
                </div>
            </div>
        </Card>
    ) : (null)

}

export default CreateMatch;