import styled from "styled-components";

export const PageDescription = styled.h4`
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #56648a;
  margin-bottom: 20px;
`;
