import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/Login';
import Forgot from './components/Pages/Forgot';
import Reset from './components/Pages/Reset';
import Dashboard from './components/Dashboard';
import { AuthConsumer } from './providers/Auth';
import { clarity } from 'react-microsoft-clarity';

const CLARITY_ID = 'ij88i581au';

function App() {
  useEffect(() => {
    clarity.init(CLARITY_ID);
  }, []);

  return (
    <AuthConsumer>
      {(context) =>
        context.authenticated ? (
          <BrowserRouter>
            <Switch>
              <Route exact path="/*" component={Dashboard} />
            </Switch>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/forgot" component={Forgot} />
              <Route exact path="/reset/:eventId" component={Reset} />
              <Redirect from="/*" to="/" />
            </Switch>
          </BrowserRouter>
        )
      }
    </AuthConsumer>
  );
}

export default App;
