import React, { useContext, useEffect, useState } from 'react';
import { StatPanelQuery, SubmittedMentors } from 'ustrive-ui';
import { run } from '../../helpers/parse';
import { UserContext } from '../../providers/User';
import PieGraph from '../Elements/PieGraph';
import UnmatchedStudents from '../Elements/UnmatchedStudents';

import ApplicationStatus from '../Elements/ApplicationStatus';
import CollegeStats from '../Elements/CollegeStats';
import LatestActivity from '../Elements/LatestActivity';
import ProfileChecklist from '../Elements/ProfileChecklist';

import { DateRangePicker } from 'react-date-range';

// Date picker
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [mentorsList, setMentorsList] = useState([]);
  const { user, college } = useContext(UserContext);
  const [showFilters, setShowFilters] = useState(false);

  const today = new Date();
  const threeYearsAgo = new Date(today);
  threeYearsAgo.setFullYear(today.getFullYear() - 3);

  const [startDate, setStartDate] = useState(threeYearsAgo);
  const [endDate, setEndDate] = useState(new Date());
  const [key, setKey] = useState('selection');

  const confirmAccount = async (id) => {
    try {
      await run('updateAccount', {
        userId: id,
        verified: true,
      });
      setMentorsList(mentorsList.filter((account) => account.id !== id));
    } catch (err) {
      console.error(err);
    }
  };

  async function getSubmittedMentors() {
    try {
      const response = await run('accountsList', {
        type: 'mentor',
        status: 'submitted',
      });
      setMentorsList(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const handleSelect = (ranges) => {
    const newSelection = {
      startDate: ranges?.selection?.startDate || new Date(),
      endDate: ranges?.selection?.endDate || new Date(),
      key: ranges.selection.key,
    };
    setStartDate(newSelection.startDate);
    setEndDate(newSelection.endDate);
    setKey(newSelection.key);
  };

  useEffect(() => {
    getSubmittedMentors();
  }, []);

  return user.branch.accountsEnabled ? (
    <div id="page">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <button
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
          onClick={() => setShowFilters(!showFilters)}>
          <FontAwesomeIcon icon={faSlidersH} color="#56648A" />
        </button>
      </div>

      {showFilters && (
        <div
          className="row"
          style={{
            position: 'absolute',
            zIndex: 100,
            right: '60px',
            border: '1px solid #f8f9fa',
            borderRadius: '8px',
            boxShadow: 'rgba(3, 3, 92, 0.08) 0px 2px 10px',
          }}>
          <DateRangePicker ranges={[{ startDate, endDate, key }]} onChange={handleSelect} maxDate={new Date()} />
        </div>
      )}
      <div className="row">
        {user.branch.studentsEnabled && (
          <div className="col-md-3">
            <StatPanelQuery
              onLoad={() =>
                run('accountsList', {
                  type: 'student',
                  count: true,
                  beforeDate: endDate?.toISOString().slice(0, 10),
                  afterDate: startDate?.toISOString().slice(0, 10),
                })
              }
              title={'Student Accounts'}
              icon={'user-graduate'}
            />
          </div>
        )}
        {user.branch.mentorsEnabled && (
          <div className="col-md-3">
            <StatPanelQuery
              onLoad={() =>
                run('accountsList', {
                  type: 'mentor',
                  count: true,
                  beforeDate: endDate?.toISOString().slice(0, 10),
                  afterDate: startDate?.toISOString().slice(0, 10),
                })
              }
              title={'Mentor Accounts'}
              icon={'user-tie'}
            />
          </div>
        )}
        <div className="col-md-3">
          <StatPanelQuery
            onLoad={() =>
              run('matchesList', {
                count: true,
                active: true,
                beforeDate: endDate?.toISOString().slice(0, 10),
                afterDate: startDate?.toISOString().slice(0, 10),
              })
            }
            title={'Active Matches'}
            icon={'user-friends'}
          />
        </div>
        <div className="col-md-3">
          <StatPanelQuery
            onLoad={() =>
              run('accountsList', {
                type: 'student',
                status: 'registered',
                count: true,
                beforeDate: endDate?.toISOString().slice(0, 10),
                afterDate: startDate?.toISOString().slice(0, 10),
              })
            }
            title={'Unmatched Students'}
            icon={'user'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          {user.branch.studentsEnabled && <UnmatchedStudents />}
          {user.branch.mentorsEnabled && <SubmittedMentors mentorsList={mentorsList} action={confirmAccount} actionText="Verify" loading={loading} />}
        </div>
        <div className="col-md-4">
          {user.branch.studentsEnabled && (
            <PieGraph
              title={'Student Status'}
              labels={['Registered', 'Matched']}
              queries={[
                {
                  endpoint: 'accountsList',
                  params: {
                    type: 'student',
                    count: true,
                    status: 'registered',
                    beforeDate: endDate?.toISOString().slice(0, 10),
                    afterDate: startDate?.toISOString().slice(0, 10),
                  },
                },
                {
                  endpoint: 'accountsList',
                  params: {
                    type: 'student',
                    count: true,
                    status: 'matched',
                    beforeDate: endDate?.toISOString().slice(0, 10),
                    afterDate: startDate?.toISOString().slice(0, 10),
                  },
                },
              ]}
            />
          )}

          {user.branch.mentorsEnabled && (
            <PieGraph
              title={'Mentor Status'}
              labels={['Registered', 'Submitted', 'Verified', 'Matched']}
              queries={[
                {
                  endpoint: 'accountsList',
                  params: {
                    type: 'mentor',
                    count: true,
                    status: 'registered',
                    beforeDate: endDate?.toISOString().slice(0, 10),
                    afterDate: startDate?.toISOString().slice(0, 10),
                  },
                },
                {
                  endpoint: 'accountsList',
                  params: {
                    type: 'mentor',
                    count: true,
                    status: 'submitted',
                    beforeDate: endDate?.toISOString().slice(0, 10),
                    afterDate: startDate?.toISOString().slice(0, 10),
                  },
                },
                {
                  endpoint: 'accountsList',
                  params: {
                    type: 'mentor',
                    count: true,
                    status: 'verified',
                    beforeDate: endDate?.toISOString().slice(0, 10),
                    afterDate: startDate?.toISOString().slice(0, 10),
                  },
                },
                {
                  endpoint: 'accountsList',
                  params: {
                    type: 'mentor',
                    count: true,
                    status: 'matched',
                    beforeDate: endDate?.toISOString().slice(0, 10),
                    afterDate: startDate?.toISOString().slice(0, 10),
                  },
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div id="page">
      <div className="row">
        <div className="col-md-9">
          <CollegeStats />
          <div className="row">
            <div className="col-md-12">
              <LatestActivity />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <ProfileChecklist college={college} />
          <ApplicationStatus />
        </div>
      </div>
    </div>
  );
};

export default Home;
