import React, { useState, useEffect } from "react";
import { run } from "../../helpers/parse";
import { downloadCsv } from "../../helpers/utils";
import { TableTools, EventTable } from "ustrive-ui";
import { useContext } from "react";
import { UserContext } from "../../providers/User";
import { StyledPage } from "../Elements/StyledPage";
import { PageDescription } from "../Elements/PageDescription";

const ENDPOINT = "eventsList";

const Events = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [excludeNames, setExcludeNames] = useState([]);

  async function filter(paginate = 0) {
    setLoading(true);
    const newPage = page + paginate;
    setExcludeNames(excludeNames); // not needed

    try {
      const response = await run(ENDPOINT, {
        excludeNames: excludeNames.map((option) => option.value),
        page: newPage,
      });

      setEvents(response);
      setPage(newPage);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  async function onExport() {
    try {
      const response = await run("createReport", {
        endpoint: ENDPOINT,
        count,
      });

      downloadCsv(response);
    } catch (err) {
      console.error(err);
    }
  }

  async function getCount() {
    try {
      const response = await run(ENDPOINT, {
        excludeNames: excludeNames.map((option) => option.value),
        count: true,
      });

      setCount(response);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getCount();
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage id="page">
      {user.branch.college && (
        <PageDescription>
          Analyze the content that {user.branch.name}’s students are accessing
          on UStrive.
        </PageDescription>
      )}
      <TableTools
        queryType="events"
        fields={["excludeEvents", "collegeId"]}
        filter={filter}
        page={page}
        count={count}
        enableExport={true}
        onExport={onExport}
      />
      <div className="row">
        <div className="col-md-12">
          <EventTable loading={loading} results={events} />
        </div>
      </div>
    </StyledPage>
  );
};

export default Events;
