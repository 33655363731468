import React, { useState, useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../providers/User";
import { Card, Avatar, Divider, Button, BasicInfo, InputField } from "ustrive-ui";
import { run } from "../../helpers/parse";
import { formatPhoneNumber, removeCountryCode } from "../../helpers/utils";
import TitleText from "../Elements/TitleText";
import DescriptionText from "../Elements/DescriptionText";

const AccountName = styled.h5`
  color: #29345c;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-top: 15px;
`;

const AccountEmail = styled.span`
  font-size: 16px;
  line-height: 21px;
  color: #8892ab;
  display: block;
  margin-top: 10px;
`;

const AccountPhone = styled.span`
  font-size: 16px;
  line-height: 21px;
  color: #8892ab;
`;

const Settings = () => {
  const { user, updateUser } = useContext(UserContext);
  const [editing, updateEditing] = useState(false);

  const onSave = async (params) => {
    try {
      await run("updateUser", params);

      updateUser({
        ...user,
        firstName: params.profile.firstName,
        lastName: params.profile.lastName,
        email: params.profile.email,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onUpload = async (avatar) => {
    try {
      await run("updateUser", {
        avatar,
      });

      updateUser({
        ...user,
        avatar,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="page">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {!editing ? (
            <Card>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Avatar size={100} url={user.avatar} />
                  <AccountName>{`${user.firstName} ${user.lastName}`}</AccountName>
                  <AccountEmail>{user.email}</AccountEmail>
                  <AccountPhone>
                    {formatPhoneNumber(removeCountryCode(user.phone))}
                  </AccountPhone>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Divider margin={20} />
                  <Button
                    className="primary"
                    width="auto"
                    onClick={() => updateEditing(true)}
                  >
                    Change Profile Info
                  </Button>
                </div>
              </div>
            </Card>
          ) : (
            <BasicInfo
              user={user}
              stopEditing={() => updateEditing(false)}
              onSave={onSave}
              onUpload={onUpload}
            />
          )}
          {
            user.branch.studentsEnabled && (
              <div className="row">
                <div className="col-md-12">
                  <TitleText>Student URL</TitleText>
                  <DescriptionText className="mb-2">Students can register for your organization at the following URL:</DescriptionText>
                  <InputField value={user.branch.studentURL} />
                </div>
              </div>
            )
          }
          {
            user.branch.mentorsEnabled && (
              <div className="row">
                <div className="col-md-12">
                  <TitleText>Mentor URL</TitleText>
                  <DescriptionText className="mb-2">Mentors can register for your organization at the following URL:</DescriptionText>
                  <InputField value={user.branch.mentorURL} />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Settings;
