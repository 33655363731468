import React, { useContext } from 'react';
import { PasswordPanel, AuthFooter } from 'ustrive-ui';
import { AuthContext } from '../../providers/Auth';
import { run, logIn } from '../../helpers/parse';
import PropTypes from 'prop-types';

const Reset = ({ match }) => {
  const auth = useContext(AuthContext);

  async function onSubmit(email, password) {
    try {
      const { eventId } = match.params;
      await run('updateUser', { email, password, eventId });
      await logIn(email.toLowerCase(), password);
      auth.setAuthenticated(true);
    } catch (err) {
      throw err;
    }
  }

  return (
    <div id="auth-page" className="row">
      <div className="col-md-4 offset-md-4">
        <PasswordPanel onSubmit={onSubmit} />
      </div>
      <AuthFooter />
    </div>
  );
};

Reset.propTypes = {
  match: PropTypes.object
}

export default Reset;
