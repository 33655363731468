import React, { useState } from "react";

import {
  Dropdown,
  InputGroup,
  InputField,
  TextareaField,
  Button,
  Divider,
} from "ustrive-ui";
import c from "./content.json";

const ProgramEditor = ({
  programs = [],
  editIndex,
  editComplete,
  updateCollege,
}) => {
  const editing = editIndex > -1;
  const editProgram = editing
    ? programs[editIndex]
    : {
        title: "",
        description: "",
        link: "",
        type: "",
      };

  const [type, setType] = useState(editProgram.type);
  const [title, setTitle] = useState(editProgram.title);
  const [link, setLink] = useState(editProgram.link);
  const [description, setDescription] = useState(editProgram.description);
  const [adding, setAdding] = useState("");

  const onSave = async () => {
    try {
      if (!type || !title || !description) return;
      setAdding(`${editing ? "Saving" : "Adding"} Program...`);
      const newPrograms = editing
        ? programs.map((p, i) =>
            i === editIndex
              ? {
                  title,
                  description,
                  link,
                  type,
                }
              : p
          )
        : programs.concat({
            title,
            type,
            link,
            description,
          });

      await updateCollege(newPrograms);
      if (editing) {
        editComplete();
      }
      setType("");
      setTitle("");
      setLink("");
      setDescription("");
      setAdding("");
    } catch (err) {
      setAdding("");
      console.error(err);
    }
  };

  return (
    <div>
      <p className="font-weight-bold">
        {editing ? "Edit program" : "Add program"}
      </p>

      <Divider margin={20} />
      <div className="row">
        <div className="col-md-12">
          <InputGroup label="Program Type" bold>
            <div data-cy="typeDropdown">
              <Dropdown value={type} onChange={setType}>
                <option>Choose One</option>
                {c.typeOptions.map((o) => (
                  <option key={o}>{o}</option>
                ))}
              </Dropdown>
            </div>
          </InputGroup>
          <div>
            <InputGroup label="Program Title" bold>
              <InputField
                type="text"
                value={title}
                placeholder={c.titlePlaceholder}
                onChange={setTitle}
              />
            </InputGroup>
          </div>
          <div>
            <InputGroup label="Program Link" bold>
              <InputField
                type="text"
                value={link}
                placeholder={c.linkPlaceholder}
                onChange={setLink}
              />
            </InputGroup>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            <InputGroup label="Program Description" bold>
              <TextareaField
                placeholder={c.descriptionPlaceholder}
                value={description}
                rows="5"
                onChange={setDescription}
              />
            </InputGroup>
          </div>
          <div className="pt-1">
            <div className="row">
              <div className="col-md-5">
                <Button
                  disabled={adding}
                  className="primary"
                  onClick={() => onSave()}
                >
                  {editing ? c.saveButton : c.addButton}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProgramEditor.defaultProps = {
  editIndex: -1,
};

export default ProgramEditor;
