import styled from "styled-components";
import { Button, TextareaField } from "ustrive-ui";

export const StyledButton = styled(Button)`
  svg {
    margin-right: 0px;
  }
`;

export const StyledTextareaField = styled(TextareaField)`
  padding: 1.75rem !important;
  font-size: 1rem !important;
`;
