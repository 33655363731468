import React from "react";
import { Card, Divider, ToastAlert } from "ustrive-ui";
import { run } from "../../../helpers/parse";
import ProgramEditor from "../../Elements/ProgramEditor";
import FeaturedPrograms from "../../Elements/FeaturedPrograms";
import { toast } from "react-toastify";
import { IconWrapper } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookAlt } from "@fortawesome/pro-solid-svg-icons";

const CollegePrograms = ({ college, onSuccess }) => {
  const programs = college.programs || [];

  const updateCollege = async (newPrograms) => {
    try {
      const response = await run("updateCollege", {
        programs: newPrograms,
      });
      onSuccess(response);
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
      console.error(err);
    }
  };

  return (
    <Card>
      <div className="d-flex">
        <IconWrapper>
          <FontAwesomeIcon size="sm" color="white" icon={faBookAlt} />
        </IconWrapper>
        <div>
          <h3 className="font-weight-bold h5">Programs</h3>
          <p className="text-muted m-0">
            Add programs that your college or university offers to their student
            community.
          </p>
        </div>
      </div>
      <Divider margin={40} />
      <div className="row">
        <div className="col-md-12">
          <FeaturedPrograms programs={programs} updateCollege={updateCollege} />
        </div>
      </div>

      <ProgramEditor programs={programs} updateCollege={updateCollege} />
    </Card>
  );
};
export default CollegePrograms;
