import React, { useState } from "react";
import {
  InputField,
  Button,
  InputGroup,
  ToastAlert,
  LoadingIcon,
} from "ustrive-ui";
import StyledCard from "../StyledCard";
import { run } from "../../../helpers/parse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faTh } from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import Reorder, { reorder } from "react-reorder";

import { LinksList } from "./elements";
import c from "./content.json";

const HelpfulLinks = ({ college, onSuccess }) => {
  const { links = [] } = college;
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [saving, setSaving] = useState("");

  async function deleteLink(index) {
    if (!window.confirm("Are you sure you want to delete this link?")) return;
    try {
      const newLinks = links.filter((l, i) => i !== index);
      setSaving("Deleting...");

      await run("updateCollege", {
        links: newLinks,
      });

      onSuccess({
        ...college,
        links: newLinks,
      });
      setSaving("");
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
    }
  }

  async function addLink() {
    try {
      if (!title) {
        return toast.error(
          <ToastAlert
            title="Link Title"
            description="Please provide a link title."
          />
        );
      }

      if (!url) {
        return toast.error(
          <ToastAlert
            title="Link URL"
            description="Please provide a link URL."
          />
        );
      }

      setSaving("Adding...");

      const newLinks = links.concat({
        title,
        url: url.indexOf("http") > -1 ? url : "http://" + url,
      });

      await run("updateCollege", {
        links: newLinks,
      });

      onSuccess({
        ...college,
        links: newLinks,
      });

      setTitle("");
      setUrl("");
      setSaving("");
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
      setSaving("");
    }
  }

  const onReorder = async (event, previousIndex, nextIndex, fromId, toId) => {
    try {
      setSaving("Reordering...");
      const newLinks = reorder(links, previousIndex, nextIndex);

      const response = await run("updateCollege", {
        links: newLinks,
      });

      onSuccess(response);
      setSaving("");
    } catch (err) {
      setSaving(false);
    }
  };

  return (
    <StyledCard padding="0px">
      <h3 className="font-weight-bold h6">{c.title}</h3>
      <p className="text-secondary">{c.description}</p>

      {saving ? (
        <div className="col-md-12 mb-4">
          <LoadingIcon />
        </div>
      ) : (
        <LinksList>
          <Reorder
            reorderId="helpful-links"
            autoScroll={false}
            holdTime={150}
            onReorder={onReorder}
          >
            {links.map((l, index) => (
              <li key={index}>
                <div id={`link-${index}`} className="d-flex align-items-center">
                  <FontAwesomeIcon
                    onClick={() => deleteLink(index)}
                    size="sm"
                    className="cursor-grab"
                    color="#A4ADC5"
                    icon={faTh}
                  />
                  <a
                    href={l.url}
                    className="font-weight-bold m-0 ml-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {l.title}
                  </a>
                </div>
                <div data-cy="deleteLink">
                  <Button
                    size="small"
                    onClick={() => deleteLink(index)}
                    className="text-danger border-0 font-weight-light"
                    width="auto"
                    hasIcon
                  >
                    <FontAwesomeIcon
                      size="sm"
                      className="text-danger"
                      color="#56648a"
                      icon={faTrashAlt}
                    />
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </Reorder>
        </LinksList>
      )}
      <h3 className="font-weight-bold h6 mt-5 mb-2">Add link</h3>

      <div className="row">
        <div className="col-md-6 px-2">
          <InputGroup>
            <InputField
              placeholder={c.linkPlaceholder}
              value={title}
              onChange={setTitle}
            />
          </InputGroup>
        </div>
        <div className="col-md-6 px-2">
          <InputGroup>
            <InputField
              placeholder={c.urlPlaceholder}
              value={url}
              onChange={setUrl}
            />
          </InputGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <Button className="outline" onClick={addLink} disabled={saving}>
            {c.addButton}
          </Button>
        </div>
      </div>
    </StyledCard>
  );
};

export default HelpfulLinks;
