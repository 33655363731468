import styled from "styled-components";
import { Card } from "ustrive-ui";

export const CoverImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 245px;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

export const SectionCard = styled(Card)`
  position: sticky;
  top: 24px;
  margin-top: 200px;
`;

export const SectionName = styled.a`
  color: #56648a;
  font-size: 14px;
`;

export const SectionIconWrapper = styled.span`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background: #e7edfb;
  margin-right: 1rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  min-width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #0c46db;
  margin-right: 1rem;
`;
