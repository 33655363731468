import React, { useState } from "react";
import { CollegeProgram, Button, Divider } from "ustrive-ui";
import { isEmpty, remove } from "lodash";
import Reorder, { reorder } from "react-reorder";
import EditButton from "../../Elements/EditButton";
import CancelButton from "../../Elements/CancelButton";
import ProgramEditor from "../../Elements/ProgramEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import c from "./content.json";
import { ProgramActions, ProgramWrapper } from "./styled";

const FeaturedPrograms = ({ programs, updateCollege }) => {
  const [editIndex, setEditIndex] = useState(-1);

  const deleteProgram = async (index) => {
    if (!window.confirm("Are you sure you want to delete this program?"))
      return;
    try {
      const newPrograms = remove(programs, (n, i) => i !== index);
      await updateCollege(newPrograms);
    } catch (err) {
      console.error(err);
    }
  };

  const onReorder = async (event, previousIndex, nextIndex, fromId, toId) => {
    const newPrograms = reorder(programs, previousIndex, nextIndex);
    await updateCollege(newPrograms);
  };

  return isEmpty(programs) ? (
    <h3 className="text-center">{c.emptyText}</h3>
  ) : (
    <Reorder
      reorderId="profile-programs"
      autoScroll={false}
      holdTime={150}
      lock="horizontal"
      disabled={editIndex > -1}
      onReorder={onReorder}
      style={{
        listStyle: "none",
        padding: "0px",
        margin: "0px",
      }}
      component="ul"
    >
      {programs.map((program, index) => (
        <li key={index}>
          <ProgramWrapper id={`program-${index}`} className="row">
            <ProgramActions
              className="col-md-4 offset-md-8 d-flex justify-content-end"
              data-cy="deleteProgram"
            >
              {editIndex !== index ? (
                <EditButton
                  dataCy={"editProgram"}
                  onClick={() => setEditIndex(index)}
                />
              ) : (
                <CancelButton
                  dataCy={"cancelEdit"}
                  onClick={() => setEditIndex(-1)}
                />
              )}
              <Button
                size="small"
                onClick={() => deleteProgram(index)}
                className="text-danger border-0 font-weight-light ml-4"
                width="auto"
                hasIcon
              >
                <FontAwesomeIcon
                  size="sm"
                  className="text-danger"
                  icon={faTrashAlt}
                />
                Delete
              </Button>
            </ProgramActions>
            <div className="col-md-12">
              {editIndex === index ? (
                <ProgramEditor
                  programs={programs}
                  editIndex={editIndex}
                  editComplete={() => setEditIndex(-1)}
                  updateCollege={updateCollege}
                />
              ) : (
                <CollegeProgram program={program} />
              )}
            </div>
          </ProgramWrapper>
          <Divider margin={40} />
        </li>
      ))}
    </Reorder>
  );
};

export default FeaturedPrograms;
