import React, { useState } from "react";
import { InputGroup, InputField, Button, Divider } from "ustrive-ui";
import c from "./content.json";

const AddVideo = ({ onSubmit }) => {
  const [title, setTitle] = useState("");

  const [url, setUrl] = useState("");
  const [adding, setAdding] = useState("");

  const onClick = async () => {
    try {
      setAdding(c.adding);
      await onSubmit({ title, url });
      setAdding("");
      setTitle("");
      setUrl("");
    } catch (err) {
      setAdding("");
    }
  };

  return (
    <div>
      <p className="font-weight-bold">{c.addButton}</p>

      <Divider margin={20} />
      <InputGroup label="Video Title" bold>
        <InputField
          type="text"
          value={title}
          placeholder={c.titlePlaceholder}
          onChange={setTitle}
        />
      </InputGroup>
      <InputGroup label="YouTube URL" bold>
        <InputField
          type="text"
          value={url}
          placeholder={c.urlPlaceholder}
          onChange={setUrl}
        />
      </InputGroup>
      <div className="row pt-2" data-cy="uploadVideo">
        <div className="col-md-5">
          <Button className="primary" disabled={adding} onClick={onClick}>
            {c.addButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddVideo;
