import React from "react";
import dayjs from "dayjs";
import { StatPanelQuery } from "ustrive-ui";

import { StatsTitle } from "./elements";
import { run } from "../../../helpers/parse";
import c from "./content.json";

const CollegeStats = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <StatsTitle>{c.header}</StatsTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <StatPanelQuery
            onLoad={() =>
              run("eventsList", {
                includeNames: ["pageLoaded"],
                userType: "student",
                count: true,
                afterDate: dayjs().subtract(12, "months").toDate(),
                collegeId: true
              })
            }
            title="Profile Views"
            icon="mouse"
          />
        </div>
        <div className="col-md-4">
          <StatPanelQuery
            onLoad={() =>
              run("eventsList", {
                includeNames: ["contactCollege"],
                userType: "student",
                count: true,
                afterDate: dayjs().subtract(12, "months").toDate(),
                collegeId: true
              })
            }
            title="Messages Received"
            icon="envelope"
          />
        </div>
        <div className="col-md-4">
          <StatPanelQuery
            onLoad={() =>
              run("listTrackers", {
                count: true,
                afterDate: dayjs().subtract(12, "months").toDate(),
              })
            }
            title="Students Applying"
            icon="user-edit"
          />
        </div>
      </div>
    </>
  );
};

export default CollegeStats;
