import React, { useState } from "react";
import { InputField, Button, InputGroup, ToastAlert } from "ustrive-ui";
import StyledCard from "../StyledCard";
import { run } from "../../../helpers/parse";
import c from "./content.json";
import { toast } from "react-toastify";

const AdmissionsEmails = ({ college, onSuccess }) => {
  const [email, setEmail] = useState(college.email);
  const [saving, setSaving] = useState("");

  async function save() {
    try {
      setSaving("Saving...");

      await run("updateCollege", {
        email,
      });

      onSuccess({
        ...college,
        email,
      });
      toast.success(
        <ToastAlert title="Email Updated" description={c.successDescription} />
      );
      setSaving("");
    } catch (err) {
      setSaving("");
      toast.error(
        <ToastAlert
          title="Update Failed"
          description="Something went wrong and the update was unsuccessful."
        />
      );
    }
  }

  return (
    <StyledCard padding="0px" margin="0px 0px 0px 0px">
      <h3 className="font-weight-bold h6">{c.title}</h3>
      <p className="text-secondary">{c.description}</p>
      <InputGroup>
        <InputField
          type="email"
          placeholder={c.placeholder}
          value={email}
          onChange={setEmail}
        />
      </InputGroup>
      <div data-cy="submit-admissions-email">
        <Button className="primary" onClick={save} disabled={saving}>
          {c.saveButton}
        </Button>
      </div>
    </StyledCard>
  );
};

export default AdmissionsEmails;
