import styled from "styled-components";

export const MentorName = styled.h1`
  color: #29345c;
  font-size: 22px;
  line-height: 31px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 10px;
`;

export const MentorCollege = styled.span`
  color: #56648a;
  font-size: 16px;
  line-height: 21px;
  display: block;
  margin-bottom: 12px;
`;

export const ContactInfo = styled.ul`
  list-style: none;
  padding: 0px;
  text-align: left;
`;

export const InfoRow = styled.li`
  font-family: "Sofia Pro";
  color: rgb(86, 100, 138);
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  svg {
    color: #0c46db;
    margin-right: 10px;
  }
`;
