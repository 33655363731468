import React, { useState } from "react";
import avatar from "../../../assets/college.png";
import { run } from "../../../helpers/parse";
import {
  LogoUpload,
  Uploader,
  ChoosePictureText,
  UploadCaption,
  MaxSizeText,
} from "./styled";

const CollegeLogo = ({ college, onSuccess }) => {
  const [imageUrl, setImageUrl] = useState(college.logo || "");

  const onUploadError = (event) => {
    console.log("onUploadError", event);
  };

  const onUploadFinish = async (event) => {
    const logo = event.publicUrl; // AWS URL
    // Send to backend to update the logo URL
    await run("updateCollege", {
      logo,
    });

    // Update college in User provider with latest logo
    const logoURL = `https://stag.ustrivestage.com${logo}`;
    onSuccess({
      ...college,
      logo: logoURL,
    });

    setImageUrl(logoURL);
  };

  return (
    <div className="col-md-12 d-flex align-items-center">
      <LogoUpload>
        <img
          className="width-100 rounded-circle"
          src={imageUrl || avatar}
          alt={college.name}
        />
        <Uploader
          multiple={false}
          signingUrl="/s3/sign"
          signingUrlMethod="GET"
          accept="image/*"
          s3path="colleges/logos/"
          server={(process.env.REACT_APP_PARSE_SERVER_URL || "").replace(
            "/api",
            ""
          )}
          onError={onUploadError}
          onSignedUrl={onUploadFinish}
          contentDisposition="auto"
          scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
          uploadRequestHeaders={{ "x-amz-acl": "private" }}
        />
      </LogoUpload>
      <div className="ml-4">
        <ChoosePictureText>Upload Logo</ChoosePictureText>
        <UploadCaption>Add a logo for your college/university.</UploadCaption>
        <MaxSizeText>Max image size is 1MB.</MaxSizeText>
      </div>
    </div>
  );
};

export default CollegeLogo;
