import React, { useState, useEffect } from "react";
import TransparentCard from "./TransparentCard";
import { Doughnut } from "react-chartjs-2";
import { run } from "../../helpers/parse";

const PieGraph = ({ title, labels, defaultData = [], queries = [] }) => {
  const [data, updateData] = useState(defaultData);

  useEffect(() => {
    async function loadQueries() {
      try {
        const response = await Promise.all(
          queries.map(query => run(query.endpoint, query.params))
        );
        updateData(response);
      } catch (err) {
        console.error(err);
      }
    }

    loadQueries();
  }, [queries]);

  return (
    <TransparentCard title={title}>
      <Doughnut
        legend={{
          position: "right",
          align: "center",
          labels: {
            boxWidth: 6,
            fontSize: 14,
            fontColor: "#56648A",
            fontFamily: "Sofia Pro",
            padding: 15
          }
        }}
        data={{
          labels,
          datasets: [
            {
              data,
              backgroundColor: ["#C9D3ED", "#4172EF"]
            }
          ]
        }}
      />
    </TransparentCard>
  );
};

export default PieGraph;
