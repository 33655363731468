import React, { useState, useEffect } from "react";
import { run } from "../../helpers/parse";
import { TableTools, MatchTable } from "ustrive-ui";
import { onExport } from "../../helpers/utils";
import { useContext } from "react";
import { UserContext } from "../../providers/User";
import { StyledPage } from "../Elements/StyledPage";
import { PageDescription } from "../Elements/PageDescription";

const ENDPOINT = "matchesList";

const Matches = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);
  const [page, setPage] = useState(0);
  const [params, updateParams] = useState({});
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [descending, setDescending] = useState(true);

  async function filter(paginate = 0) {
    setLoading(true);
    const newPage = page + paginate;
    updateParams(params);

    try {
      const response = await run(ENDPOINT, {
        page: newPage,
        active: true,
        orderBy,
        descending,
      });
      setMatches(response);
      setPage(newPage);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  async function getCount() {
    try {
      const response = await run(ENDPOINT, {
        count: true,
      });

      setCount(response);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateOrder(_orderBy, _descending) {
    setOrderBy(_orderBy);
    setDescending(_descending);
  }

  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, descending]);

  return (
    <StyledPage id="page">
      {user.branch.college && (
        <PageDescription>
          See the mentors that {user.branch.name}’s students are matched to.
        </PageDescription>
      )}
      <TableTools
        filter={filter}
        fields={["active"]}
        page={page}
        count={count}
        queryType="matches"
        enableExport={true}
        onExport={() => onExport(ENDPOINT, params, count)}
      />
      <div className="row">
        <div className="col-md-12">
          <MatchTable
            orderBy={orderBy}
            descending={descending}
            updateOrder={updateOrder}
            loading={loading}
            results={matches}
          />
        </div>
      </div>
    </StyledPage>
  );
};

export default Matches;
