import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

const MentorSteps = ({ mentor }) => {

    const [onboardingSteps, setOnboardingSteps] = useState([]);

    useEffect(() => {

        setOnboardingSteps([{
            label: 'Verified Phone',
            done: mentor.phoneVerified
        },{
            label: 'Profile Picture',
            done: mentor.avatar
        },{
            label: 'Short Bio',
            done: mentor.bio
        },{
            label: 'Education History',
            done: (mentor.education || []).length > 0
        },{
            label: 'Employment Info',
            done: mentor.info.employer && mentor.info.occupation
        },{
            label: `Training Modules (${mentor.training.length}/4)`,
            done: mentor.training.length === 4
        }]);

    }, [mentor]);

    return (
        <ul id="mentor-onboarding">
            {
                onboardingSteps.map((step, idx) => (<li key={idx}><FontAwesomeIcon icon={step.done ? faCheckCircle : faCircle } color={step.done ? "#4caf50" : "#E0E4ED" } /> {step.label}</li>))
            }
        </ul>
    )

};

export default MentorSteps;