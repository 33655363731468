import React, { useState } from 'react';
import { Button } from 'ustrive-ui';
import { run } from '../../helpers/parse';

const VerifyAccount = ({ mentor, onSuccess }) => {

    const [verifying, setVerifying] = useState(false);

    async function verifyAccount() {
        
        setVerifying('Verifying...');

        try {

            await run('updateAccount', {
                userId: mentor.id,
                verified: true
            });

            setVerifying(true);
            onSuccess();

        } catch(err) {
            console.error(err);
        }
    }

    return (
        <React.Fragment>
            <p>This mentor has completed all onboarding steps &amp; submitted their account for verification. You can verify their account by clicking the button below.</p>
            <Button className="primary" onClick={verifyAccount} disabled={verifying}>Verify Account</Button>
        </React.Fragment>
    )

}

export default VerifyAccount;