import React from "react";
import { toast } from "react-toastify";
import { Card, Divider, ToastAlert } from "ustrive-ui";
import { run } from "../../../helpers/parse";
import AddVideo from "../../Elements/AddVideo";
import FeaturedVideos from "../../Elements/FeaturedVideos";
import { IconWrapper } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

const CollegeVideos = ({ college, onSuccess }) => {
  const { videos = [] } = college;

  const onSubmit = async (payload) => {
    try {
      const newVideos = videos.concat(payload);
      const response = await run("updateCollege", {
        videos: newVideos,
      });
      onSuccess(response);
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
    }
  };

  const updateCollege = async (newVideos) => {
    const response = await run("updateCollege", {
      videos: newVideos,
    });
    onSuccess(response);
  };

  return (
    <React.Fragment>
      <Card>
        <div className="d-flex">
          <IconWrapper>
            <FontAwesomeIcon size="sm" color="white" icon={faPlay} />
          </IconWrapper>
          <div>
            <h3 className="font-weight-bold h5">Featured Videos</h3>
            <p className="text-muted m-0">
              Add links to YouTube videos that you would like to embed on your
              profile for visitors to watch.
            </p>
          </div>
        </div>

        <Divider margin={20} />
        <div className="row">
          <div className="col-md-12 px-0">
            <FeaturedVideos videos={videos} updateCollege={updateCollege} />
          </div>
          <div className="col-md-12">
            <AddVideo onSubmit={onSubmit} />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default CollegeVideos;
