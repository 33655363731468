import React from "react";
import styled from "styled-components";
import { Sidebar, SidebarItem, SidebarDivider } from "ustrive-ui";

const SidebarPadding = styled.div`
  padding: 0px 20px;
`;
const DIVIDER_MARGIN = 10;

const SupportMenu = () => (
  <>
    <SidebarPadding>
      <SidebarDivider margin={DIVIDER_MARGIN} />
    </SidebarPadding>
    <SidebarItem title="Settings" icon="cog" link="/settings" />
    <SidebarItem title="Contact Us" icon="envelope" link="/contact" />
  </>
);

const CollegeMenu = () => (
  <>
    <SidebarItem title="Edit Profile" icon="pencil" link="/edit" />
    <SidebarItem title="View Profile" icon="window" link="/profile" />
    <SidebarItem title="Applying" icon="user-edit" link="/applying" />
    <SidebarItem title="Viewed" icon="mouse" link="/viewed" />
    <SidebarItem title="Contacted" icon="envelope" link="/contacted" />
    <SidebarPadding>
      <SidebarDivider margin={DIVIDER_MARGIN} />
    </SidebarPadding>
 </>
);

const MainMenu = ({ branch }) =>
  branch.accountsEnabled ? (
    <Sidebar>
      {branch.college && <CollegeMenu />}
      <SidebarItem title="Dashboard" icon="home" link="/dashboard" />
      {branch.studentsEnabled && (
        <SidebarItem title="Students" icon="user-graduate" link="/students" />
      )}
      <SidebarItem title="Matches" icon="user-friends" link="/matches" />
      {branch.mentorsEnabled && (
        <SidebarItem title="Mentors" icon="user-tie" link="/mentors" />
      )}
      <SidebarItem title="Messages" icon="comments" link="/messages" />
      <SidebarItem title="Events" icon="database" link="/events" />
      <SupportMenu />
    </Sidebar>
  ) : (
    <Sidebar>
      <CollegeMenu />
      <SidebarItem title="Dashboard" icon="home" link="/dashboard" />
      <SupportMenu />
    </Sidebar>
  );

export default MainMenu;
