import React, { useState, useEffect } from "react";
import { run } from "../../helpers/parse";
import { TableTools, UserTable } from "ustrive-ui";
import { onExport, getCount } from "../../helpers/utils";

const ENDPOINT = "accountsList";

const Mentors = ({ history, location }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [params, updateParams] = useState({
    type: "mentor"
  });

  async function filter(paginate = 0, values = []) {
    setLoading(true);

    const newPage = page + paginate;

    const filterParams = Object.assign(
      {},
      ...values.map(param => ({ [param["name"]]: param.value }))
    );

    const newParams = {
      ...filterParams,
      type: "mentor",
      page: newPage,
    };

    try {
      const countResponse = await getCount(ENDPOINT, newParams);
      const response = await run(ENDPOINT, newParams);
      setResults(response);
      setCount(countResponse);
      setPage(newPage);
      updateParams(newParams);
      updateURL(newParams);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const updateURL = params => {
    history.push(`/mentors?status=${params.status}`);
  };

  console.log(history);
  console.log(location);

  useEffect(() => {
    (async function init() {
      console.log("init.");
      const response = await getCount(ENDPOINT, params);
      setCount(response);
    })();
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="page">
      <TableTools
        filter={filter}
        fields={['search','accountStatus']}
        page={page}
        count={count}
        enableExport={true}
        onExport={() => onExport(ENDPOINT, params, count)}
        queryType="accounts"
      />
      <div className="row">
        <div className="col-md-12">
          <UserTable
            emptyText={`No mentors found.`}
            loading={loading}
            results={results}
            columns={["Mentor", "Email", "Status", "Registered"]}
            type="mentor"
          />
        </div>
      </div>
    </div>
  );
};

export default Mentors;
