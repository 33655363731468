import styled from "styled-components";

export const LinksList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px 0px 10px 0px;
  li {
    width: 100%;
    cursor: pointer;
    padding: 15px 0px 8px 0px;
    border-bottom: 1px solid #e1e6f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: #56648a;
    }
    svg {
      cursor: pointer;
    }
    :last-of-type {
      border-bottom: 0px;
    }
  }
`;
