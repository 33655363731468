import styled from "styled-components";

export const ProgramWrapper = styled.div`
  position: relative;

  .col-md-12 > div {
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
    a.btn {
      display: none;
    }
    h2 {
      font-weight: 700;
    }
  }
`;

export const ProgramActions = styled.div`
  position: absolute !important;
  top: -0.5rem;

  button {
    border: none;
    padding: 0;
    height: initial;
  }
`;
