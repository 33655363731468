import React, { useState } from 'react';
import Parse from 'parse';

const AuthContext = React.createContext();
const AuthConsumer = AuthContext.Consumer;

const AuthProvider = (props) => {
  const [authenticated, setAuthenticated] = useState(Parse.User.current());

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export {
  AuthContext,
  AuthConsumer,
  AuthProvider
};
