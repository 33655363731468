import styled from 'styled-components';

const TitleText = styled.h2`
  color: #29345c;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
`;

export default TitleText;
