import React from 'react';
import { ForgotPanel, AuthFooter } from 'ustrive-ui';
import { run } from '../../helpers/parse';

const Forgot = () => {
  async function onSubmit(email) {
    try {
      await run('createEvent', { email, name: 'forgotPassword' });
    } catch (err) {
      throw err;
    }
  }

  return (
    <div id="auth-page" className="row">
      <div className="col-md-4 offset-md-4">
        <ForgotPanel onSubmit={onSubmit} hideRegister />
      </div>
      <AuthFooter />
    </div>
  );
};

Forgot.propTypes = {};

export default Forgot;
