import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Header } from "ustrive-ui";
import { UserProvider, UserConsumer } from "../providers/User";
import MainMenu from "./Layout/MainMenu";
import Home from "./Pages/Home";
import Account from "./Pages/Account";
import Matches from "./Pages/Matches";
import Messages from "./Pages/Messages";
import Events from "./Pages/Events";
import Students from "./Pages/Students";
import Mentors from "./Pages/Mentors";
import Match from "./Pages/Match";

import Settings from "./Pages/Settings";
import Contact from "./Pages/Contact";

/* Colleges Components */
import Applying from "./Pages/Colleges/Applying";
import Contacted from "./Pages/Colleges/Contacted";
import Viewed from "./Pages/Colleges/Viewed";
import Profile from "./Pages/Colleges/Profile";
import Edit from "./Pages/Colleges/EditProfile";

import { logOut } from "../helpers/parse";

const Dashboard = ({ history }) => {
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    setPathname(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <UserProvider>
      <div id="dashboard">
        <UserConsumer>
          {({ user, college }) => (
            <>
              <MainMenu branch={user.branch} />
              <div id="content">
                <Header
                  pathname={pathname}
                  title="Dashboard"
                  user={user}
                  logOut={logOut}
                  headerImage={
                    user.branch.logo || (college ? college.logo : null)
                  }
                />
                <Switch>
                  <Route exact path="/dashboard" component={Home} />
                  <Route exact path="/students" component={Students} />
                  <Route exact path="/mentors" component={Mentors} />
                  <Route exact path="/account/:id" component={Account} />
                  <Route exact path="/matches" component={Matches} />
                  <Route exact path="/matches/:objectId" component={Match} />
                  <Route exact path="/messages" component={Messages} />
                  <Route exact path="/events" component={Events} />

                  {/* Colleges Routes */}
                  <Route exact path="/applying" component={Applying} />
                  <Route exact path="/contacted" component={Contacted} />
                  <Route exact path="/viewed" component={Viewed} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/edit" component={Edit} />

                  <Route exact path="/settings" component={Settings} />
                  <Route exact path="/contact" component={Contact} />

                  <Redirect from="/*" to="/dashboard" />
                </Switch>
              </div>
            </>
          )}
        </UserConsumer>
      </div>
    </UserProvider>
  );
};

export default Dashboard;
