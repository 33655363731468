import React, { useState, useEffect } from 'react';
import { LoadingIcon } from 'ustrive-ui';
import { run, currentUser, logOut } from '../helpers/parse';

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

const UserProvider = (props) => {
  const [user, updateUser] = useState(null);
  const [college, updateCollege] = useState(null);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await run('getUser');
        if (response.branch.college) {
          const collegeResponse = await run('getCollege');
          updateCollege(collegeResponse);
          updateUser(response);
        } else {
          updateUser(response);
        }
      } catch (err) {
        if (currentUser()) logOut();
        window.location = "/login";
      }
    }

    getUser();
  }, []);

  return user ? (
    <UserContext.Provider value={{ user, updateUser, college, updateCollege }}>
      { props.children }
    </UserContext.Provider>
  ) : (
    <div id="page">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Loading UStrive...</h2>
          <LoadingIcon />
        </div>
      </div>
    </div>
  );
};

export {
  UserContext,
  UserConsumer,
  UserProvider
};
