import React from "react";
import styled from "styled-components";

import { Button } from "ustrive-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-light-svg-icons";

const StyledButton = styled(Button).attrs(props => ({
  'data-cy': props.dataCy
}))`
  border: 0px;
  text-align: right;
  :hover {
    color: #0c46db;
    svg {
      color: #0c46db;
    }
  }
`;

const EditButton = ({ onClick, dataCy }) => (
  <StyledButton dataCy={dataCy} width="auto" hasIcon onClick={onClick}>
    <FontAwesomeIcon icon={faPencilAlt} color="#29345C" size="sm" /> Edit
  </StyledButton>
);

export default EditButton;
