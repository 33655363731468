import React, { useState } from "react";
import { Card, Button, LoadingIcon } from "ustrive-ui";
import { run } from "../../../helpers/parse";
import { isEmpty, remove } from "lodash";
import Reorder, { reorder } from "react-reorder";
import ReactCrop from "react-image-crop";
import ImageUpload from "../../Elements/ImageUpload";
import { ButtonDiv, BannerImage, ImageWrapper, DeleteImage } from "./styled";
import c from "./content.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimes } from "@fortawesome/pro-light-svg-icons";

const CROP_CONFIG = {
  x: 0,
  y: 0,
  height: 48,
  width: 120,
  aspect: 5 / 2,
  minWidth: 1200,
  minHeight: 480,
};

let startUpload = null;

const CollegeImages = ({ college, onSuccess }) => {
  // const [images, setImages] = useState(college.images || []);
  const [crop, setCrop] = useState(CROP_CONFIG);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [saving, setSaving] = useState(false);

  const onUploadStart = (file, next) => {
    setCrop(CROP_CONFIG);
    setImageUrl(URL.createObjectURL(file));
    startUpload = next;
  };

  const onUploadError = (event) => {
    console.log("onUploadError", event);
  };

  const onUploadFinish = async (event) => {
    const images = college.images.concat(event.publicUrl);

    const response = await run("updateCollege", {
      images,
    });

    onSuccess(response);

    // setImages(response.partner.images);
    setCrop(CROP_CONFIG);
    setImage(null);
    setSaving(false);
  };

  const onImageChange = (crop) => {
    setCrop(crop);
  };

  const onImageChangeComplete = (crop) => {
    setCrop(imageUrl ? crop : CROP_CONFIG);
  };

  const onImageLoaded = (image) => {
    setImage(image);
  };

  const getCroppedImg = async (image, crop, fileName) => {
    if (!image) return;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 1200;
    canvas.height = 480;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      1200,
      480
    );

    // As a blob
    const response = new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        blob.name = fileName;
        resolve(blob);
      }, "image/jpeg");
    });

    return response;
  };

  const handleSave = async () => {
    if (!crop.width && !crop.height) {
      window.confirm("Please crop your profile picture before saving.");
    }

    setSaving(true);

    const response = await getCroppedImg(image, crop, "banner");
    startUpload(response);
    setImageUrl("");
    setImage(null);
  };

  const cancelCrop = () => {
    setImageUrl("");
    setImage(null);
  };

  const deleteImage = async (index) => {
    if (!window.confirm("Are you sure you want to delete this image?")) return;
    setSaving(true);
    try {
      const images = remove(college.images, (n, i) => {
        return i !== index;
      });
      const response = await run("updateCollege", {
        images,
      });

      onSuccess(response);
      setSaving(false);
      // setImages(newImages);
    } catch (err) {
      console.error(err);
    }
  };

  const onReorder = async (event, previousIndex, nextIndex, fromId, toId) => {
    try {
      setSaving(true);
      const images = reorder(college.images, previousIndex, nextIndex);
      const response = await run("updateCollege", {
        images,
      });

      onSuccess(response);
      // setImages(reorder(images, previousIndex, nextIndex));
      setSaving(false);
    } catch (err) {
      setSaving(false);
    }
  };

  return (
    <div>
      <div className="justify-content-between mb-4 align-items-center">
        <h3 className="font-weight-bold h5">{c.header}</h3>
        <p className="text-secondary">You can upload a max of 6 photos.</p>
      </div>
      <div className="row">
        {saving ? (
          <div className="col-md-12 mb-4">
            <LoadingIcon />
          </div>
        ) : (
          <div className="col-md-12 p-0">
            {isEmpty(college.images) ? (
              <h3 className="text-center">{c.emptyText}</h3>
            ) : (
              <Reorder
                reorderId="slideshow-images"
                autoScroll={false}
                holdTime={150}
                style={{
                  listStyle: "none",
                  padding: "0px",
                  margin: "0px",
                }}
                className="row"
                onReorder={onReorder}
                component="div">
                {!isEmpty(college.images) &&
                  college.images.map((img, index) => (
                    <ImageWrapper className="col-md-4 p-2">
                      <li key={index}>
                        <Card padding="0" className="mb-4">
                          <div className="row">
                            <div className="col-md-12">
                              <BannerImage
                                alt={`image${index}`}
                                key={index}
                                src={
                                  img.startsWith("/s3/uploads")
                                    ? `https://colleges.ustrive.com/api${img}`
                                    : img
                                }
                                className="width-100"
                              />
                            </div>
                          </div>
                        </Card>
                      </li>
                      <DeleteImage
                        data-cy="deleteImage"
                        className="delete-button"
                        onClick={() => deleteImage(index)}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="#FF4949"
                          size="sm"
                        />
                      </DeleteImage>
                    </ImageWrapper>
                  ))}
              </Reorder>
            )}
          </div>
        )}
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              {college.images?.length < 6 && (
                <ImageUpload
                  cypress="uploadImage"
                  text={
                    <div className="d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faUpload}
                        color="#29345C"
                        size="lg"
                      />
                      <p className="ml-3 m-0">Upload New Photo</p>
                    </div>
                  }
                  onUploadStart={onUploadStart}
                  onUploadError={onUploadError}
                  onUploadFinish={onUploadFinish}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <ReactCrop
                src={imageUrl}
                onChange={onImageChange}
                onComplete={onImageChangeComplete}
                onImageLoaded={onImageLoaded}
                crop={crop}
                keepSelection={true}
              />
              {imageUrl && (
                <ButtonDiv>
                  <Button size="small" width="auto" onClick={cancelCrop}>
                    Cancel Upload
                  </Button>
                  <div data-cy="submitImage">
                    <Button size="small" width="auto" onClick={handleSave}>
                      Save Image
                    </Button>
                  </div>
                </ButtonDiv>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeImages;
