import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  min-width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #0c46db;
  margin-right: 1rem;
`;
