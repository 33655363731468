import React, { useContext } from "react";
import { CollegeProfile, LoadingIcon } from "ustrive-ui";
import { UserContext } from "../../../providers/User";

const Profile = () => {
  const { college } = useContext(UserContext);

  return (
    <div id="page">
      {college ? <CollegeProfile college={college} /> : <LoadingIcon />}
    </div>
  );
};

export default Profile;
