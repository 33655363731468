import styled from 'styled-components';

const CardHeader = styled.h5`
    margin-top: 0px;
    margin-bottom: 10px;
    color: #29345C;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-family: "Sofia Pro Medium";
`;

export default CardHeader;