import React from "react";
import styled from "styled-components";
import ReactS3Uploader from "react-s3-uploader";

const Uploader = styled(ReactS3Uploader)`
  display: none;
`;

const StyledLabel = styled.label`
  display: block;
  color: #56648a;
  background: #fafbfd;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  border: dashed 2px #adb3c7;
  text-align: center;
  cursor: pointer;
`;

const ImageUpload = ({
  text,
  onUploadStart,
  onUploadError,
  onUploadFinish,
  cypress,
}) => (
  <StyledLabel>
    {text}
    <Uploader
      data-cy={cypress}
      multiple={false}
      signingUrl="/s3/sign"
      signingUrlMethod="GET"
      accept="image/*"
      s3path="colleges/images/"
      server={(process.env.REACT_APP_PARSE_SERVER_URL || "").replace(
        "/api",
        ""
      )}
      preprocess={onUploadStart}
      onError={onUploadError}
      onFinish={onUploadFinish}
      contentDisposition="auto"
      scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
      uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
    />
  </StyledLabel>
);

export default ImageUpload;
