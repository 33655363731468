import React, { useState, useEffect } from "react";
import { TableTools, EventTable } from "ustrive-ui";
import { run } from "../../../helpers/parse";
import { downloadCsv } from "../../../helpers/utils";
import { StyledPage } from "../../Elements/StyledPage";
import { PageDescription } from "../../Elements/PageDescription";
import { useContext } from "react";
import { UserContext } from "../../../providers/User";

const endpoint = "eventsList";

const includeNames = ["contactCollege"];

const Contacted = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  async function filter(paginate = 0, values = []) {
    setLoading(true);
    const newPage = page + paginate;

    const filterParams = Object.assign(
      {},
      ...values.map((param) => ({ [param["name"]]: param.value }))
    );

    const newParams = {
      ...filterParams,
      includeNames,
      page: newPage,
      userType: "student",
      collegeId: true,
    };

    try {
      const response = await run(endpoint, newParams);

      setEvents(response);
      setPage(newPage);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  async function onExport() {
    try {
      const response = await run("createReport", {
        endpoint,
        includeNames,
        count,
        userType: "student",
        collegeId: true,
      });
      downloadCsv(response);
    } catch (err) {
      console.error(err);
    }
  }

  async function getCount() {
    try {
      const response = await run(endpoint, {
        count: true,
        includeNames,
        userType: "student",
        collegeId: true,
      });

      setCount(response);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    filter();
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage id="page">
      {user.branch.college && (
        <PageDescription>
          These students have sent a message to {user.branch.college.email}{" "}
          using the contact form on {user.branch.name}'s profile.
        </PageDescription>
      )}
      <TableTools
        filter={filter}
        page={page}
        fields={["search", "graduation"]}
        enableExport={true}
        onExport={onExport}
        count={count}
      />
      <div className="row">
        <div className="col-md-12">
          <EventTable
            emptyText="No events found."
            loading={loading}
            results={events}
            columns={["User", "Grade", "Timestamp"]}
          />
        </div>
      </div>
    </StyledPage>
  );
};

export default Contacted;
