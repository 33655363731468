import Parse from 'parse';
import mixpanel from 'mixpanel-browser';

async function logIn(email = '', password = '') {
  try {
    if (!email) throw new Error("Please provide an email address.");
    const user = await Parse.User.logIn(email.toLowerCase(), password);
    mixpanel.identify(user.id);
    mixpanel.people.set();
    mixpanel.track('Logged In');
    return user;
  } catch (err) {
    throw err;
  }
}

async function logOut() {
  try {
    const response = await Parse.User.logOut();
    window.location = "/login";
    return response;
  } catch(err) {
    throw err;
  }
}

async function register(params) {
  try {
    const token = await Parse.Cloud.run('createUser', params);
    const user = await Parse.User.become(token);
    mixpanel.identify(user.id);
    mixpanel.people.set();
    return user;
  } catch (err) {
    throw err;
  }
}

async function run(endpoint, params) {
  try {
    const response = await Parse.Cloud.run(endpoint, params);
    return response;
  } catch (err) {
    throw err;
  }
}

async function subscribe(_class) {
  try {
    const subscription = await new Parse.Query(_class)
      .notContainedIn('name', ['createMessage','pageLoaded'])
      .equalTo('user', Parse.User.current())
      .subscribe();
    return subscription;
  } catch (err) {
    throw err;
  }
}

function currentUser() {
  return Parse.User.current();
}

async function become(token) {
 
    try {
      if (!token) throw Error("No token.");
      const user = await Parse.User.become(token);
      mixpanel.identify(user.id);
      return user;
    } catch (err) {
      throw err;
    }
  
}

export {
  logIn,
  logOut,
  register,
  run,
  subscribe,
  currentUser,
  become
};
