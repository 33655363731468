import { Card } from "ustrive-ui";
import styled from "styled-components";

const StyledCard = styled(Card)`
  background: transparent !important;
  box-shadow: none !important;
  margin: ${props => props.margin || 'auto'}
`;

export default StyledCard;
