import styled from "styled-components";
import ReactS3Uploader from "react-s3-uploader";

export const LogoUpload = styled.label`
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  :hover {
    opacity: 0.9;
  }
`;

export const Uploader = styled(ReactS3Uploader)`
  display: none;
`;

export const ChoosePictureText = styled.h2`
  font-weight: bold;
  font-family: Sofia Pro Medium;
  color: #1d2b42;
  font-size: 16px;
  line-height: 25px;
  margin: 0px;
`;

export const UploadCaption = styled.span`
  color: #a9b0c5;
  font-size: 14px;
  margin: 0.25rem 0;
  line-height: 19px;
  display: block;
`;

export const MaxSizeText = styled.span`
  color: #56648a;
  font-size: 14px;
  line-height: 19px;
  display: block;
`;
