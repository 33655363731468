import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
  Card,
  CircleIcon,
  Divider,
  InputGroup,
  InputField,
  GoalTitle,
  GoalDescription,
  SaveSettings
} from "ustrive-ui";
import TextAreaAutosize from "react-autosize-textarea";
import { currentUser } from "../../helpers/parse";

const TicketTextarea = styled(TextAreaAutosize)`
  background: #ffffff;
  border: 1px solid #d1d8e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 16px;
  color: #56648a;
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  resize: none;
  :focus {
    outline: none;
  }
`;

const Contact = () => {
  const [body, updateBody] = useState("");
  const [subject, updateSubject] = useState("");

  async function createTicket() {
    try {
      if (!subject) throw Error("Please provide a subject.");
      if (!body) throw Error("Please provide some more info.");
      await axios.post(
        process.env.REACT_APP_PARSE_SERVER_URL.replace(
          "/api",
          "/incoming/ticket"
        ),
        {
          subject,
          body,
          sessionToken: currentUser().get("sessionToken")
        }
      );
      updateBody("");
      updateSubject("");
    } catch (err) {
      throw err;
    }
  }

  return (
    <div id="page">
      <div className="col-md-8 offset-md-2">
        <Card>
          <div className="d-flex align-items-center">
            <CircleIcon icon="envelope" size={60} />
            <div className="ml-3">
              <GoalTitle>Contact Support</GoalTitle>
              <GoalDescription>
                Use the form below to contact the UStrive team with any
                questions, comments or concerns. We can typically respond within
                1 business day.
              </GoalDescription>
            </div>
          </div>
          <Divider margin={20} />
          <InputGroup label="Subject" bold>
            <InputField
              placeholder="A subject for your message..."
              value={subject}
              onChange={updateSubject}
            />
          </InputGroup>
          <TicketTextarea
            placeholder="Enter some information for the UStrive team..."
            rows={4}
            value={body}
            onChange={ev => updateBody(ev.target.value)}
          />
          <Divider margin={20} />
          <SaveSettings
            onClick={createTicket}
            successText="We've received your message and will reply as soon as possible."
            submittingText="Submitting..."
            buttonText="Create Ticket"
          />
        </Card>
      </div>
    </div>
  );
};

export default Contact;
