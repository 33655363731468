import styled from "styled-components";

export const AboutName = styled.h3`
  color: #29345c;
  font-size: 18px;
  line-height: 22px;
  margin: 0px;
`;

export const MentorBio = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #56648a;
`;

export const HelperSpan = styled.span`
  color: #56648a;
  line-height: 14px;
  margin-right: 5px;
`;
