import React, { useContext } from "react";
import { Card, Divider } from "ustrive-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChurch, faBookAlt, faPlay } from "@fortawesome/pro-solid-svg-icons";

import { UserContext } from "../../../../providers/User";

import AdmissionsEmails from "../../../Elements/AdmissionsEmail";
import HelpfulLinks from "../../../Elements/HelpfulLinks";
import SearchTermsPanel from "../../../Elements/SearchTerms";
import CollegeLogo from "../../../Elements/CollegeLogo";
import CollegeImages from "../../../Elements/CollegeImages";
import CollegePrograms from "../../../Elements/CollegePrograms";
import CollegeVideos from "../../../Elements/CollegeVideos";
import CollegeOverview from "../../../Elements/CollegeOverview";
import {
  SectionCard,
  SectionName,
  SectionIconWrapper,
  IconWrapper,
  CoverImage,
} from "./styled";

const EditProfile = () => {
  const { college, updateCollege } = useContext(UserContext);

  const sections = [
    {
      name: "General Info",
      description:
        "Adding general information about your school helps improve visibility & adds extra information to your profile.",
      icon: faChurch,
      href: "#basic-info",
    },
    { name: "Programs", icon: faBookAlt, href: "#programs" },
    {
      name: "Featured Videos",
      description:
        "Add links to YouTube videos that you would like to appear on your profile.",
      icon: faPlay,
      href: "#featured-videos",
    },
  ];

  return (
    <div id="page" className="position-relative">
      <CoverImage imageUrl={college.images[0]} />
      <div className="row">
        <div className="col-md-3">
          <SectionCard className="position-sticky top-10" padding="36px 25px">
            <div className="row">
              <div className="col-md-12">
                <h3 className="font-weight-bold h6 mb-3">Sections</h3>
                {sections.map(({ name, icon, href }) => (
                  <div key={name} className="d-flex mb-2 align-items-center">
                    <SectionIconWrapper>
                      <FontAwesomeIcon size="sm" color="#0C46DB" icon={icon} />
                    </SectionIconWrapper>
                    <SectionName href={href}>{name}</SectionName>
                  </div>
                ))}
              </div>
            </div>
          </SectionCard>
        </div>
        <div className="col-xl-7 col-lg-9">
          <div id="basic-info">
            <Card padding="40px">
              <div className="row">
                <CollegeLogo college={college} onSuccess={updateCollege} />
              </div>
              <Divider margin={40} />
              <div className="d-flex">
                <IconWrapper>
                  <FontAwesomeIcon size="sm" color="white" icon={faChurch} />
                </IconWrapper>
                <div>
                  <h3 className="font-weight-bold h5">{sections[0].name}</h3>
                  <p className="text-muted m-0">{sections[0].description}</p>
                </div>
              </div>
              <Divider margin={40} />
              <div className="row">
                <div className="col-md-12">
                  <CollegeOverview
                    college={college}
                    onSuccess={updateCollege}
                  />
                </div>
              </div>
              <Divider margin={40} />
              <div className="row">
                <div className="col-md-12">
                  <CollegeImages college={college} onSuccess={updateCollege} />
                </div>
              </div>
              <Divider margin={40} />
              <div className="row">
                <div className="col-md-12">
                  <AdmissionsEmails
                    college={college}
                    onSuccess={updateCollege}
                  />
                </div>
              </div>
              <Divider margin={40} />
              <div className="row">
                <div className="col-md-12">
                  <HelpfulLinks college={college} onSuccess={updateCollege} />
                </div>
              </div>
              <Divider margin={40} />
              <div className="row">
                <div className="col-md-12">
                  <SearchTermsPanel
                    college={college}
                    onSuccess={updateCollege}
                  />
                </div>
              </div>
            </Card>
          </div>

          <div id="programs" className="row">
            <div className="col-md-12">
              <CollegePrograms college={college} onSuccess={updateCollege} />
            </div>
          </div>
          <div id="featured-videos" className="row">
            <div className="col-md-12">
              <CollegeVideos college={college} onSuccess={updateCollege} />
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-0"></div>
      </div>
    </div>
  );
};

export default EditProfile;
