import React, { useState, useEffect, useContext } from "react";
import { LoadingIcon } from "ustrive-ui";
import { UserContext } from "../../providers/User";
import { run } from "../../helpers/parse";
import CollegesAccount from "../Elements/CollegesAccount";
import ReportsAccount from "../Elements/ReportsAccount";

const Account = ({ match, history }) => {
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getAccount() {
    setLoading(true);

    const { id } = match.params;

    try {
      const response = await run("accountsGet", {
        id,
      });

      setProfile(response);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const refresh = () => {
    getAccount();
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  if (loading || !profile) {
    return (
      <div id="page">
        <LoadingIcon />
      </div>
    );
  }

  return !user.branch.studentsEnabled && !user.branch.mentorsEnabled ? (
    <CollegesAccount profile={profile} />
  ) : (
    <ReportsAccount profile={profile} history={history} refresh={refresh} />
  );
};

export default Account;
