import React, { useState, useContext } from "react";
import {
  Card,
  MatchTable,
  NotificationsTable,
  MessageTable,
  EventTable,
  Divider,
  InputGroup,
  SwitchToggle,
  ToastAlert,
} from "ustrive-ui";
import { toast } from "react-toastify";
import AccountCard from "../AccountCard";
import { UserContext } from "../../../providers/User";
import CreateMatch from "../../Elements/CreateMatch";
import MentorSteps from "../../Elements/MentorSteps";
import VerifyAccount from "../../Elements/VerifyAccount";
import CardHeader from "../../Elements/CardHeader";
import { run } from "../../../helpers/parse";
import { capitalize, toggleType } from "../../../helpers/utils";
import { AboutName, MentorBio, HelperSpan } from "./styled";

const ReportsAccount = ({ profile, history, refresh }) => {
  const { user } = useContext(UserContext);
  const [emails, setEmails] = useState([]);
  const [messages, setMessages] = useState([]);
  const [events, setEvents] = useState([]);

  const getEmailsList = async () => {
    const emails = await run("emailsList", { userId: profile.id });
    setEmails(emails);
  };

  const getMessagesList = async () => {
    const messages = await run("messagesList", { userId: profile.id });
    setMessages(messages);
  };

  const getEvents = async () => {
    const events = await run("eventsList", { userId: profile.id });
    setEvents(events);
  };

  const viewMatch = (id) => {
    history.push(`/matches/${id}`);
  };

  const onRefresh = () => {
    getMessagesList();
    getEvents();
    getEmailsList();
    refresh();
  };

  const toggleUpdateAccount = async (value, type) => {
    try {
      await run("updateAccount", {
        userId: profile.id,
        [type]: value,
      });
      await refresh();
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
    }
  };

  return (
    <div id="page">
      <div className="row">
        <div className="col-md-4">
          <Card>
            <AccountCard contactInfo profile={profile} />
            <Divider margin={20} />
            {profile.type === "mentor" && (
              <InputGroup>
                <div className="d-flex align-items-center justify-content-between">
                  <HelperSpan>Available</HelperSpan>
                  <SwitchToggle
                    onChange={(val) => toggleUpdateAccount(val, "available")}
                    checked={profile.available}
                  />
                </div>
              </InputGroup>
            )}
          </Card>
        </div>
        <div className="col-md-8">
          {profile.type === "student" && user.branch.manualMatching && (
            <CreateMatch student={profile} onSuccess={onRefresh} />
          )}
          {profile.type === "mentor" &&
            !profile.config.verified &&
            profile.matches.length === 0 && (
              <Card>
                <div className="row">
                  <div className="col-md-12">
                    <AboutName>Mentor Onboarding</AboutName>
                    <Divider margin={24} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {profile.config.submitted ? (
                      <VerifyAccount mentor={profile} onSuccess={onRefresh} />
                    ) : (
                      <MentorBio>
                        This mentor has not submitted their account for
                        verification. They will need to complete the onboarding
                        steps before their account is submitted.
                      </MentorBio>
                    )}
                  </div>
                  <div className="col-md-6">
                    <MentorSteps mentor={profile} />
                  </div>
                </div>
              </Card>
            )}
          {profile.type === "mentor" &&
            profile.config.verified &&
            profile.matches.length === 0 && (
              <Card>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>
                      This mentor has been verified, but has not been matched.
                    </p>
                  </div>
                </div>
              </Card>
            )}
          <div className="row">
            <div className="col-md-12">
              <CardHeader>Active Matches</CardHeader>
              <MatchTable
                results={profile.matches}
                visitMatch={viewMatch}
                columns={[capitalize(toggleType(profile.type)), "Matched"]}
              />
              <CardHeader>Inactive Matches</CardHeader>
              <MatchTable
                results={profile.inactiveMatches}
                viewMatch={viewMatch}
                columns={[capitalize(toggleType(profile.type)), "Matched"]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {profile.type === "mentor" && (
                <Card>
                  <AboutName>{`About ${profile.firstName}`}</AboutName>
                  <Divider margin={24} />
                  <MentorBio>{profile.bio}</MentorBio>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
      {messages && (
        <div className="row">
          <div className="col-md-12">
            <MessageTable
              title="Messages"
              emptyText="No messages found."
              loading={false}
              results={messages}
            />
          </div>
        </div>
      )}
      {events && (
        <div className="row">
          <div className="col-md-12">
            <EventTable
              title="Events"
              emptyText="No events found."
              emptyIcon="folder-open"
              results={events}
              loading={false}
              columns={["User", "Event", "Timestamp"]}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <NotificationsTable
            title="Email Notifications"
            emptyText="No notifications found."
            results={emails}
            loading={false}
            columns={["Subject", "Timestamp"]}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <NotificationsTable
            title="SMS Notifications"
            emptyText="No SMS found."
            results={sms}
            loading={false}
            columns={["From", "Event", "Timestamp"]}
          />
        </div>
      </div> */}
    </div>
  );
};

export default ReportsAccount;
