import React, { useState } from "react";
import { InputField, Button, InputGroup, ToastAlert } from "ustrive-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import StyledCard from "../StyledCard";
import { run } from "../../../helpers/parse";

import c from "./content.json";
import { LinksList } from "./styled";
import { toast } from "react-toastify";

const ENDPOINT = "updateCollege";

const SearchTermsPanel = ({ college, onSuccess }) => {
  const { terms } = college;
  const [title, setTitle] = useState("");
  const [saving, setSaving] = useState("");

  const deleteTerm = async (index) => {
    if (!window.confirm(c.confirmDelete)) return;
    try {
      const newTerms = terms.filter((l, i) => i !== index);
      await run(ENDPOINT, {
        terms: newTerms,
      });
      onSuccess({
        ...college,
        terms: newTerms,
      });
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
    }
  };

  const addTerm = async () => {
    if (!title) return window.alert(c.termParam);
    try {
      setSaving(c.savingText);
      const newTerms = terms.concat(title.toLowerCase());
      await run(ENDPOINT, {
        terms: newTerms,
      });

      onSuccess({
        ...college,
        terms: newTerms,
      });

      setTitle("");
      setSaving("");
    } catch (err) {
      setSaving("");
      toast.error(<ToastAlert description={err.message} />);
    }
  };

  return (
    <StyledCard padding="0px" className="mb-2">
      <h3 className="font-weight-bold h6">{c.title}</h3>
      <p className="text-secondary">{c.description}</p>
      <LinksList>
        {terms.map((l, index) => (
          <li key={index}>
            <p className="font-weight-bold m-0">{l}</p>
            <div data-cy="deleteTerm">
              <Button
                size="small"
                onClick={() => deleteTerm(index)}
                className="text-danger border-0 font-weight-light"
                width="auto"
                hasIcon
              >
                <FontAwesomeIcon
                  size="sm"
                  className="text-danger"
                  icon={faTrashAlt}
                />
                Delete
              </Button>
            </div>
          </li>
        ))}
      </LinksList>
      <h3 className="font-weight-bold h6 mt-5 mb-3">Add Search Term</h3>
      <InputGroup>
        <InputField
          placeholder={c.placeholder}
          value={title}
          onChange={setTitle}
        />
      </InputGroup>
      <div className="row">
        <div className="col-md-5">
          <Button className="outline" onClick={addTerm} disabled={saving}>
            {c.addButton}
          </Button>
        </div>
      </div>
    </StyledCard>
  );
};

export default SearchTermsPanel;
