import React from 'react';
import styled from 'styled-components';
import { Card } from 'ustrive-ui';
import CardHeader from './CardHeader';

const StyledCard = styled(Card)`
    background: transparent !important;
    box-shadow: none !important;
`;

const CardBody = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(3, 3, 92, 0.08);
    border-radius: 8px;
    padding: 24px;
`;

const TransparentCard = ({ title, children }) => (
    <StyledCard padding="0px">
        <CardHeader>{title}</CardHeader>
        <CardBody>
            { children }
        </CardBody>
    </StyledCard>
);

export default TransparentCard;