import React, { useEffect, useContext } from "react";
import { LoginPanel, AuthFooter } from "ustrive-ui";
import { AuthContext } from "../providers/Auth";
import { logIn, become } from "../helpers/parse";

const Login = ({ location }) => {
  const auth = useContext(AuthContext);

  async function onSubmit(email, password) {
    try {
      await logIn(email.toLowerCase(), password);
      auth.setAuthenticated(true);
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    const { search } = location;
    if (search.indexOf("?session=") > -1) {
      become(search.split("?session=")[1]).then(() =>
        auth.setAuthenticated(true)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div id="auth-page" className="row"
      style={{
        height: "auto",
        position: "relative"
      }}
    >
      <div className="col-md-4 offset-md-4">
        <LoginPanel
          title="Reports Dashboard"
          onSubmit={onSubmit}
          hideRegister={true}
        />
      </div>
      <AuthFooter />
    </div>
  );
};

export default Login;
