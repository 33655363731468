import moment from "moment";
import React from "react";
import { Card, AccountCard } from "ustrive-ui";
import { formatPhoneNumber } from "../../../helpers/utils";
import { TitleText, StyledList } from "./styled";

const CollegesAccount = ({ profile }) => {
  const demographics = profile.demographics || {};
  return (
    <div id="page">
      <div className="row">
        <div className="col-md-4">
          <Card>
            <AccountCard contactInfo profile={profile} />
          </Card>
          <Card>
            <TitleText>Mentor History</TitleText>
            <StyledList>
              <li>
                <span>Active Matches:</span> {profile.matches.length}
              </li>
              <li>
                <span>Inactive Matches:</span> {profile.inactiveMatches.length}
              </li>
            </StyledList>
          </Card>
        </div>
        <div className="col-md-8">
          <Card>
            <TitleText>About {profile.firstName}</TitleText>
            <StyledList>
              {profile.info.birthdate && (
                <li>
                  <span>Birthday:</span> {profile.info.birthdate} (
                  {moment().diff(moment(profile.info.birthdate), "years")} years
                  old)
                </li>
              )}
              {profile.zip && (
                <li>
                  <span>Zip Code:</span> {profile.zip}
                </li>
              )}
              {demographics.ethnicity && (
                <li>
                  <span>Ethnicity:</span> {demographics.ethnicity}
                </li>
              )}
              {demographics.gender && (
                <li>
                  <span>Gender:</span> {demographics.gender}
                </li>
              )}
              {demographics.generation !== undefined && (
                <li>
                  <span>First Generation:</span>{" "}
                  {demographics.generation ? "Yes" : "No"}
                </li>
              )}
              {demographics.lunch && (
                <li>
                  <span>Low-Income Household:</span>{" "}
                  {demographics.lunch === "yes" ? "Yes" : "No"}
                </li>
              )}
              {profile.majors.length > 0 && (
                <li>
                  <span>Majors of Interest:</span>{" "}
                  {profile.majors.map((m) => m.name).join(", ")}
                </li>
              )}
            </StyledList>
          </Card>
          <Card>
            <TitleText>Contact Information</TitleText>
            <StyledList>
              <li>
                <span>Email Address:</span> {profile.email}
              </li>
              <li>
                <span>Phone Number:</span> {formatPhoneNumber(profile.phone)}
              </li>
            </StyledList>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CollegesAccount;
